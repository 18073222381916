<template>
	<div>
		<template>
			<v-toolbar
        flat
        color="primary"
        dark
      >
        <v-toolbar-title>Teszt - {{test.name}} - {{test.date}} {{test.Group && test.Group.length!={}?`(${test.Group.name})`:''}}</v-toolbar-title>
      </v-toolbar>
			<v-row>
				<v-btn @click="dialogs.manageExercises.show = true" color="success" rounded><v-icon>fa-dumbbell</v-icon>Gyakorlatok kezelése</v-btn>
				<v-spacer/>
				<v-btn v-if="changeable" @click="dialogs.finishTest.show = true" color="amber darken-1 " rounded><v-icon>fa-check-circle</v-icon>Teszt befejezése</v-btn>
			</v-row>
			<resultLog
        :EMUs="exerciseMUs"
        :exercises="exercises"
        :measurementUnits="measurementUnits"
        :changeable="changeable"
        :criteria="test.Criteria"
        :kidAthletes="test.Group ? (test.Group.KidAthletes? test.Group.KidAthletes: []) : []"
        :results="test.Results"
        :id="$route.params.id"
				type="test"
				:enablePoints="true"
				:ageGroups="ageGroups"
      />
			<v-dialog
	      v-model="dialogs.manageExercises.show"
	      fullscreen
	      hide-overlay
	      transition="dialog-bottom-transition"
	    >
	      <v-card>
	        <v-toolbar
	          dark
	          color="primary"
	        >
	          <v-btn
	            icon
	            dark
	            @click="dialogs.manageExercises.show = false"
	          >
	            <v-icon>fa-times</v-icon>
	          </v-btn>
	          <v-toolbar-title>Teszt gyakorlatainak kezelése</v-toolbar-title>
	        </v-toolbar>
					<v-data-table
						:headers="tables['dialog-manageExercises_Exercises'].headers"
						:items="getTestExercises"
						disable-pagination
						hide-default-footer
					>
						<template v-slot:top v-if="changeable">
							<v-row>
								<v-col align="center" justify="center" cols="12" :sm="3" class="text--secondary text-left text-right">
									Gyakorlat hozzáadása
								</v-col>
								<v-col cols="12" :sm="4">
									<v-select
										:items="ageGroups.map(aG => {return {name: `${aG.name} - ${aG.shortname}`, id: aG.id}})"
										item-text="name"
										item-value="id"
										v-model="newCriterium.AgeGroupId"
										rounded
										outlined
										prepend-inner-icon="fa-child"
										label="Korcsoport"
									/>
								</v-col>
								<v-col cols="12" :sm="4">
									<v-select
										:items="getCriteriaOfAgeGroup"
										item-text="name"
										item-value="criterium"
										v-model="newCriterium.criterium"
										rounded
										outlined
										prepend-inner-icon="fa-dumbbell"
										label="Gyakorlat"
									/>
								</v-col>
								<v-col cols="12" :sm="1">
									<v-btn fab color="success" @click="addCriterium" v-if="newCriterium.criterium!=undefined"><v-icon>fa-plus</v-icon></v-btn>
								</v-col>
							</v-row>
						</template>
						<template v-slot:item.exercise="{item}">
							<v-btn text :to="{name: 'exercise', params: {id:item.eID}}">{{item.exercise}}</v-btn>

						</template>
						<template v-slot:item.delete="{item}">
							<v-tooltip top>
								Törlés
								<template v-slot:activator="{on, attrs}">
									<v-btn :disabled="!changeable" v-on="on" v-bind="attrs" icon color="red accent-4" @click="dialogs['manageExercises-deleteCriterium'].criteriumId=item.id, dialogs['manageExercises-deleteCriterium'].show=true"><v-icon>fa-trash</v-icon></v-btn>
								</template>
							</v-tooltip>
						</template>
					</v-data-table>
	      </v-card>
	    </v-dialog>
			<v-dialog
	      v-model="dialogs['manageExercises-deleteCriterium'].show"
				persistent
				max-width="600px"
	    >
				<v-card>
					<v-card-title>
						<span class="headline">Gyakorlat törlése</span>
					</v-card-title>
					<v-card-text>
						<v-container>
							Biztosan törli a gyakorlatot a tesztből? (Ezzel a már rögzített eredmények is törlődnek)
						</v-container>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							color="blue darken-1"
							text
							@click="dialogs['manageExercises-deleteCriterium'].show = false"
						>
							Mégsem
						</v-btn>
						<v-btn
							color="blue darken-1"
							text
							@click="deleteCriterium()"
						>
							Törlés
						</v-btn>
					</v-card-actions>
				</v-card>

	    </v-dialog>
			<v-dialog
	      v-model="dialogs.finishTest.show"
				persistent
				max-width="600px"
	    >
				<v-card>
					<v-card-title>
						<span class="headline">Teszt befejezése</span>
					</v-card-title>
					<v-card-text>
						<v-container>
							Biztosan befejezi a tesztet? (Később már nem módosíthat az eredményeken, illetve nem adhat hozzá eredményt.)
						</v-container>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							color="blue darken-1"
							text
							@click="dialogs.finishTest.show = false"
						>
							Mégsem
						</v-btn>
						<v-btn
							color="blue darken-1"
							text
							@click="finishTest()"
						>
							befejezés
						</v-btn>
					</v-card-actions>
				</v-card>

	    </v-dialog>
	   </template>
	</div>
</template>

<script>
	import router from '../router';
	import attendanceRegistry from '../components/groupAttendanceRegistry.vue'
	import resultLog from '../components/resultLog.vue'
	export default {
		name: 'Test',
		components: {
			resultLog
		},
		data: function(){
			return {
        test: {},
        ageGroups: [],
        exerciseMUs: [],
        measurementUnits: [],
        exercises: [],
				dialogs: {
					manageExercises: {
						show: false
					},
					"manageExercises-deleteCriterium": {
						show: false,
						criteriumId: null
					},
					finishTest: {
						show: false
					}
				},
				tables: {
					"dialog-manageExercises_Exercises": {
						headers: [
							{
								text: "Gyakorlat",
								align: "center",
								sortable: false,
								filterable: false,
								groupable: false,
								value: "exercise"
							},
							{
								text: "Korcsoport",
								align: "center",
								sortable: false,
								filterable: false,
								groupable: false,
								value: "ageGroup"
							},
							{
								text: "Törlés",
								align: "center",
								sortable: false,
								filterable: false,
								groupable: false,
								value: "delete"
							}
						]
					}
				},
				newCriterium: {
					AgeGroupId: 2,
					criterium: undefined
				}
      }

		},
		computed: {
			getTestExercises() {
				return this.test.Criteria? this.test.Criteria.map(C =>{
					const ageGroup = this.ageGroups.find(aG => aG.id==C.AgeGroupId)
					const eMU = this.exerciseMUs.find(EMU => EMU.id==C.ExerciseMUId)
					const mU = this.measurementUnits.find(MU => eMU?MU.id==eMU.MeasurementUnitId:false)
					const e = this.exercises.find(E => eMU?E.id==eMU.ExerciseId:false)
					return {
						id: C.id,
						ageGroup: ageGroup ? `${ageGroup.name} - ${ageGroup.shortname}` : null,
						exercise: `${e?e.name:''}${mU?' ('+mU.unit+')':''}`,
						eID: e?e.id:null
					}
				}):[]
			},
			getCriteriaOfAgeGroup() {
				const aG = this.ageGroups.find(AG => AG.id==this.newCriterium.AgeGroupId)
				this.newCriterium.criterium=undefined
				return aG?aG.ExerciseMU_Criteria.filter(EMUC => this.test.Criteria.filter(C => C.id==EMUC.id).length==0).map(EMUC => {
					const eMU = this.exerciseMUs.find(EMU =>EMU.id==EMUC.ExerciseMUId);
					const e = eMU!==undefined&&eMU!==null ? this.exercises.find(E => E.id==eMU.ExerciseId):null
					const mU = eMU!==undefined&&eMU!==null ? this.measurementUnits.find(MU => MU.id==eMU.MeasurementUnitId):null
					return {criterium: EMUC, name: `${e?e.name:null}${e&&mU?' ('+mU.unit+')':''}${e!==null&&e!==undefined?(e.Exercise_Categories.length>0?' - '+e.Exercise_Categories.map(EC => EC.name).join(', '):''):''}`}
				}):[]
			},
			changeable() {
				return !this.test.isFinished&&this.test.testerId===this.$store.getters.userId
			}
		},
		watch: {

		},
		methods: {
			finishTest: function() {
				this.axios({url: `test/finish/${this.$route.params.id}`, method: 'POST'}).then(response => {
					if(response.data.success) {
						this.test.isFinished=true;
						this.dialogs.finishTest.show=false;
						this.$store.commit('setSnack','A teszt véglegesítve lett')
					}
				})
			},
			addCriterium: function() {
				this.axios({url: `test/${this.$route.params.id}/criterium/${this.newCriterium.criterium.id}`, method: 'POST'}).then(response => {
					if(response.data.success) {
						this.test.Criteria.push({...this.newCriterium.criterium, AgeGroupId: this.newCriterium.AgeGroupId})
						this.$store.commit('setSnack', 'A hozzáadás sikeresen megtörtént.')

					}
				})
			},
			deleteCriterium: function() {
				this.axios({url: `test/${this.$route.params.id}/criterium/${this.dialogs["manageExercises-deleteCriterium"].criteriumId}`, method: 'DELETE'}).then(response => {
					if(response.data.success){
						this.test.Criteria.splice(this.test.Criteria.findIndex(C => C.id==this.dialogs["manageExercises-deleteCriterium"].criteriumId),1);
						this.dialogs["manageExercises-deleteCriterium"].show=false;
						this.$store.commit('setSnack', 'A törlés sikeresen megtörtént.')
					}
				})
			},
      getTest: function() {
        this.axios({url: `test/${this.$route.params.id}/`, method: "GET"}).then(response => {
          if(response.data.success) {
            this.test=response.data.data.test
            this.ageGroups=response.data.data.ageGroups;
            this.exerciseMUs=response.data.data.exerciseMUs;
            this.measurementUnits=response.data.data.measurementUnits;
            this.exercises=response.data.data.exercises;
          }
        })
      }
		},
		mounted(){
      this.getTest()
		}
	}
</script>
